import type { TarifCipaBase } from "models";

export const TarifsCipa: Array<TarifCipaBase> = [
  {
    code: "OFRAISCO",
    description: "CIPA domestique jusqu'à 3 logements",
    unit: "€",
    commentaire: "A facturer 1X jusqu'à 3 logements contrôlés",
  },
  {
    code: "OFRAILOG",
    description: "Majoration pour CIPA collectif (> 3 logements)",
    unit: "€/logement supplémentaire",
    commentaire: "CIPA domestique + mettre le nombre de logements - 3",
  },
  {
    code: "OFRAIENT",
    description: "Majoration pour CIPA industriel/artisanal/tertiaire assimilé domestique",
    unit: "€/m²",
    commentaire: "CIPA domestique + m² surface X montant",
  },
  {
    code: "OFRAIIND",
    description: "Majoration pour CIPA industriel/artisanal/tertiaire non domestique",
    unit: "€/m²",
    commentaire: "CIPA domestique + m² surface X montant",
  },
  {
    code: "OFRAIMNB",
    description: "CIPA surface non bâtie",
    unit: "€",
    commentaire: "A facturer dès que la surface est > à 330m²",
  },
  {
    code: "OFRAICVI",
    description: "Visites supplémentaires",
    unit: "",
    commentaire: "",
  },
];
