export enum ToastMessages {
  CREATED_ADRESSE = "L'adresse a été créée avec succès.",
  CREATED_CHANTIER = "Le chantier a été créé avec succès.",
  GENERATED_COURRIER = "Le courrier a été généré avec succès.",
  LOCKED_COURRIER = "Le courrier ne peut pas être généré car il est verrouillé. Il est peut-être encore ouvert par un autre utilisateur.",
  ERROR_CREATION_ADRESSE = "Une erreur est survenue lors de la création de l'adresse.",
  UPDATED_TARIFS = "Les tarifs ont été modifiés avec succès.",

  ERROR = "Oups ! Une erreur est survenue.",
  ERROR_RETRY = "Une erreur est survenue. Merci de réessayer ultérieurement.",
  ERROR_UPDATE = ERROR_RETRY,
  ERROR_NOT_FOUND = "Nous n'avons pas trouvé la ressource à laquelle vous essayez d'accéder.",
  ERROR_FORBIDDEN = "Vous n'avez pas les droits nécessaires pour accéder à cette ressource.",
  ERROR_INTERNAL_SERVER_ERROR = ERROR,

  ERROR_FORM_VALIDATION = "Merci de renseigner tous les champs obligatoires (marqués d'une étoile *) et de corriger les erreurs de validation.",
  ERROR_LOADING_GENERATED_DOCUMENTS_AUDIT = "Une erreur est survenue lors du chargement de l'historique des courriers. Merci de réessayer ultérieurement.",
}
