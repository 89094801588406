import {
  Acceptation,
  DiametreBranchement,
  EmplacementCompteur,
  NatureConduite,
  TypeRejet,
} from "models";

function getEmplacementCompteurLabel(emplacement: EmplacementCompteur): string {
  switch (emplacement) {
    case EmplacementCompteur.AUTRE:
      return "Autre";
    case EmplacementCompteur.CAVE:
      return "Cave";
    case EmplacementCompteur.REGARD_BETON:
      return "Un regard béton";
    case EmplacementCompteur.REGARD_LEGER:
      return "Un regard léger";

    default:
      return emplacement;
  }
}

function getNatureConduiteLabel(natureConduite: NatureConduite): string {
  switch (natureConduite) {
    case NatureConduite.AUTRE:
      return "Autre";
    case NatureConduite.BETON:
      return "Béton";
    case NatureConduite.FONTE:
      return "Fonte";
    case NatureConduite.GRES:
      return "Grès";
    case NatureConduite.PEHD:
      return "PEHD";
    case NatureConduite.PVC:
      return "PVC";
    case NatureConduite.PVC_PRESSION:
      return "PVC Pression";

    default:
      return natureConduite;
  }
}

function getDiametreBranchementLabel(diametreBranchement: DiametreBranchement): string {
  switch (diametreBranchement) {
    case DiametreBranchement.d0:
      return "0";
    case DiametreBranchement.d32:
      return "32";
    case DiametreBranchement.d40:
      return "40";
    case DiametreBranchement.d50:
      return "50";
    case DiametreBranchement.d63:
      return "63";
    case DiametreBranchement.d90:
      return "90";
    case DiametreBranchement.d100:
      return "100";
    case DiametreBranchement.d125:
      return "125";
    case DiametreBranchement.d150:
      return "150";
    case DiametreBranchement.d160:
      return "160";
    case DiametreBranchement.d200:
      return "200";
    case DiametreBranchement.d250:
      return "250";
    case DiametreBranchement.d300:
      return "300";

    default:
      return diametreBranchement;
  }
}

function getAcceptationLabel(acceptation?: Acceptation): string {
  switch (acceptation) {
    case Acceptation.NON:
      return "Non";
    case Acceptation.OUI:
      return "Oui";

    default:
      return "En attente de réponse";
  }
}

function getTypeRejetLabel(typeRejet?: TypeRejet): string {
  switch (typeRejet) {
    case TypeRejet.USAGER_DOMESTIQUE:
      return "Usager domestique";
    case TypeRejet.USAGER_ASSIMILABLE_DOMESTIQUE:
      return "Usager assimilable domestique";
    case TypeRejet.USAGER_NON_DOMESTIQUE:
      return "Usager non domestique";
    default:
      return "";
  }
}

export {
  getAcceptationLabel,
  getEmplacementCompteurLabel,
  getDiametreBranchementLabel,
  getNatureConduiteLabel,
  getTypeRejetLabel,
};
