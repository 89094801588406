import type { ReactNode } from "react";
import { appiConfig, authenticationConfig, config } from "config/app-config";
import { AuthenticationProvider, AppInsightsProvider } from "@sdeapps/react-core";
import { authenticatedRouter, unAuthenticatedRouter } from "routes";
import { RouterProvider } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import { SnackbarProvider } from "notistack";
import { SDEAppsThemeProvider } from "providers";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { grey } from "@mui/material/colors";
import { BaseErrorPage } from "utils/errorHandling";
import { TechnicienAvatarProvider } from "providers/TechnicienAvatarProvider";

function App(): ReactNode {
  return (
    <Grid container className="App" sx={{ minHeight: "100vh" }} direction="column" columns={1}>
      <SDEAppsThemeProvider>
        <Grid size={1} columns={1}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
            <TechnicienAvatarProvider>
              <AppInsightsProvider
                onError={BaseErrorPage}
                instrumentationKey={appiConfig.instrumentationKey}
                appVersion={config.appVersion}>
                <SnackbarProvider
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  preventDuplicate>
                  <AuthenticationProvider
                    unauthorizedPage={<RouterProvider router={unAuthenticatedRouter} />}
                    config={authenticationConfig}>
                    <RouterProvider router={authenticatedRouter} />
                  </AuthenticationProvider>
                </SnackbarProvider>
              </AppInsightsProvider>
            </TechnicienAvatarProvider>
          </LocalizationProvider>
        </Grid>
        <Typography variant="body2" sx={{ color: grey[500], textAlign: "center", p: 1 }}>
          Version de build: {config.appVersion ?? "local"}
        </Typography>
      </SDEAppsThemeProvider>
    </Grid>
  );
}

export default App;
